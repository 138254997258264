import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import EditProductInformation from "./section/EditProductInformation";
import EditProductPriceAndStocks from "./section/EditProductPriceAndStocks";
import EditShortAndLongDescription from "./section/EditShortAndLongDescription";
import EditProductImages from "./section/EditProductImages";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import EditCategoriesAndSubCategories from "./section/EditCategoriesAndSubCategories";
import EditSpecialityCategoriesAndSubCategories from "./section/EditSpecialityCategoriesAndSubCategories";
import EditProductType from "./section/EditProductType";
import EditPairProduct from "./section/EditPairProduct";
import connection from '../auth/api';

const EditProduct = () => {

    const { id } = useParams();

    const [product, setProduct] = useState();
    const [oldDiscount, setOldDiscount] = useState();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        connection.get(`/get/product?id=${id}`).then(res => {
            var tempProduct = res.data.data
            setProduct({ ...tempProduct, galleryImagesAdded: [] })
            setOldDiscount(tempProduct.discount || 0);
            setLoading(false)
        })
    }, [id])
    console.log("OLD DISCOUNT", oldDiscount)
    useEffect(() => {
        console.log("product", product)
        if (product && Object.keys(product).length > 0) {
            setProduct(prev => {
                const newDiscount = parseInt(oldDiscount) === 0 && product?.isGeneralProduct ? 15 : oldDiscount;

                if (prev.discount === newDiscount) return prev;

                return { ...prev, discount: newDiscount };
            });
        }
    }, [product?.isGeneralProduct, oldDiscount]);

    if (loading) {
        return (
            <FullScreenProgress open={loading} setOpen={setLoading} />
        )
    } else {
        return (
            <div>
                <EditProductInformation product={product} setProduct={setProduct} />
                <EditProductImages setProduct={setProduct} setLoading={setLoading} product={product} />
                <EditProductType setProduct={setProduct} product={product} />
                {
                    product.isGeneralProduct ?
                        <EditCategoriesAndSubCategories setProduct={setProduct} product={product} /> :
                        <EditSpecialityCategoriesAndSubCategories product={product} setProduct={setProduct} />
                }
                <EditProductPriceAndStocks product={product} setProduct={setProduct} />
                <EditPairProduct product={product} setProduct={setProduct} />
                <EditShortAndLongDescription product={product} setProduct={setProduct} />
            </div>
        );
    }
};

export default EditProduct;
